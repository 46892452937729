html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
.ctrl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: rgb(255 255 255 / 70%);
  padding: 10px;
}
.ctrl > .title {
  text-align: center;
}
.btns {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  /* z-index: 10; */
  padding: 10px;
  display: flex;
}
.buttons {
  display: flex;
}
.buttons .button {
  cursor: pointer;
  padding: 5px 10px;
  animation: all 0.3s;
}
.buttons .button.active {
  color: #0c88e8;
}

.ctrl button {
  margin: 0 5px;
}
.BMap_cpyCtrl,
.anchorBL img {
  display: none;
}
.BMap_stdMpCtrl {
  right: 0 !important;
  top: 20px !important;
  inset: unset !important;
}
.BMap_stdMpPan {
  position: fixed !important;
  right: 18px;
  top: 50px;
}
.BMap_stdMpZoom {
  position: fixed !important;
  right: 0;
  top: 100px !important;
}
